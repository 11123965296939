import {Message, Radio} from 'rsuite'
import moment from 'moment'
import 'moment/locale/tr'
import {teslimatTarihiHesapla} from "../../../services/CustomerActions";

export const getCargoDayMessage = () => {
  const today = moment()?.format('dddd')
  moment.locale('tr')
  return getCargoDay(today)
}


const comingCargoDay = day => `Bugün sipariş verirseniz en geç ${day} kargoda!`
// eslint-disable-next-line consistent-return
const getMomenOfCargo = (day) => {
  const [saat, ifade, deger] = moment()?.startOf('day')?.add(17, 'hours')?.fromNow()
    ?.split(' ')
  if (deger === 'sonra') return `${saat} ${ifade} içerisinde sipariş verirseniz`
  if (day === 'Cumartesi') return comingCargoDay('Salı günü')
  if (day === 'Salı') return comingCargoDay('Çarşamba')
  if (day === 'Çarşamba') return comingCargoDay('Cumartesi günü')
}
const getCargoDay = day => (day === 'Pazartesi' || day === 'Cuma' ? comingCargoDay('Yarın')
  : day === 'Perşembe' ? comingCargoDay('Cumartesi günü')
    : day === 'Pazar' ? comingCargoDay('Salı günü')
      : day === 'Cumartesi' || day === 'Salı' || day === 'Çarşamba' ? getMomenOfCargo(day)
        : null)
const InfoMessage = () => (
  <div className='info-comp'>
    <h4>Tahmini teslimat:<span> {teslimatTarihiHesapla()}</span></h4>
    <Radio checked>{getCargoDayMessage()}</Radio>
  </div>
)
export default InfoMessage
